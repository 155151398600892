let StatisticsTime = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr

	/**
	 * 获取浏览记录列表数据
	 * @param {any} success
	 * @param {any} error
	 */
	this.StatisticsTimes = function(str,params, success, error) {
		var url = ServiceBaseAddress + '/api/StatisticsTime/'+str+'/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 新增浏览记录
	 * @param {any} success
	 * @param {any} error
	 */
	this.Put = function(
		type,
		learnName,
		entityKey,
		entityId,
		entityType,
		startTime,
		stayTime,
		userId,
		hospitalId,
		departmentId,
		success,
		error
	) {
		var url = ServiceBaseAddress + '/api/StatisticsTime/Put'
		var param = {
			type: type,
			learnName: learnName,
			entityKey: entityKey,
			entityId: entityId,
			startTime: startTime,
			stayTime: stayTime,
			entityType: entityType,
			userId: userId,
			hospitalId: hospitalId,
			departmentId: departmentId,
		}
		const formData = new FormData()
		Object.keys(param).forEach((key) => {
			let value = param[key]
			if (typeof value !== 'string') {
				// formData只能append string 或 Blob
				value = JSON.stringify(value)
			}
			formData.append(key, value)
		})
		navigator.sendBeacon(url, formData)
	}
}

export { StatisticsTime }
