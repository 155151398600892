<template>
    <div class="Message xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>浏览记录</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>浏览<em>记录</em></span>
                        <!-- <el-link type="danger" :underline="false" class="el-icon-delete" @click="deleteAll"></el-link> -->
                    </div>
                    <!--    内容    -->
                    <div class="center-content">
                        <el-table class="tab" :data="tableData" style="width: 100%" :show-header="false"
                            @row-click="rowClick">
                            <!-- <el-table-column
                                    type="selection"
                                    width="45">
                            </el-table-column> -->
                            <el-table-column prop="learnName" label="">
                            </el-table-column>
                            <el-table-column prop="startTime" label="" width="150" align="right">
                            </el-table-column>
                            <el-table-column prop="stayTime" label="" width="150" align="left">
                                <template slot-scope="scope"> <span style="color: #1fb7a6;">(浏览时长:{{ scope.row.stayTime
}})</span></template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="15" :current-page="PageIndex"
                            :total="BrowsTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { StatisticsTime } from "../../components/statisticsTime/StatisticsTime";
import { converName, FormatSecond,reversalName } from "../../utils/extend";
import { clickName } from "../../utils/relation";
export default {
    name: "Browsing",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var statisticsTime = new StatisticsTime(this.TokenClient, this.Services.Statics)
        return {
            labelPosition: 'right',
            tableData: [],
            false: false,
            statisticsTimeDoamin: statisticsTime,
            PageIndex: 1,
            PageTotal: 1,
            BrowsTotal: 0,
            str: this.$store.state.localData.typeList.toString(),
        };
    },
    methods: {
        init: function () {
            var _this = this;
            _this.statisticsTimeDoamin.StatisticsTimes(_this.str,_this.PageIndex,
                function (data) {
                    _this.tableData = []
                    data.data.results.forEach(element => {
                        element.startTime = _this.$moment(element.startTime).format("YYYY-MM-DD HH:mm:ss");
                        _this.tableData.push({
                            type: element.type,
                            learnName: `【${converName(element.type)}】 ${element.learnName}`,
                            entityKey: element.entityKey,
                            startTime: element.startTime,
                            stayTime: FormatSecond(element.stayTime)
                        })
                    });
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.BrowsTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err)
                });
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.init();
        },
        rowClick: function (row, even, column) {
            clickName(row.type, row.entityKey)
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    min-height: 740px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right-tit .el-icon-delete {
    float: right;
    font-size: 20px;
    margin-top: 10px;
}

.center-content {
    padding: 0 20px;
}

.tab /deep/ .el-table__row:hover {
    cursor: pointer;
}
</style>
<style>
.el-table::before {
    height: 0;
}

.el-table td,
.el-table th.is-leaf {
    border-bottom: 1px dashed #EBEEF5;
}

.center-content .el-pagination {
    padding: 10px 5px;
}
</style>
